<div style="width: 100vw;">
  <!-- Custom Header -->
<div class="topNavWrapper flex-wrap">
  <img
    style="cursor: pointer"
    [routerLink]="['/']"
    src="assets/images/unilockedlogopgn-08.png"
    width="118.73px"
    alt="logo"
  />
  <div
    *ngIf="currentIndex != 4"
    class="d-flex flex-row align-items-center rightSide flex-wrap"
  >
    <span>Zaten hesabınız var mı?</span>
    <button routerLink="/login" type="button" class="btn lightButton">
      Giriş Yapın
    </button>
  </div>
</div>
<div class="left-side d-flex flex-column">
  <mat-stepper
    *ngIf="registerAvaible; else noRegister"
    [linear]="true"
    (selectedIndexChange)="currentIndex = $event"
    #stepper
  >
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <div class="d-flex flex-column gap-3">
          <!-- header -->
          <div class="d-flex flex-column gap-1 headerWrapper">
            <h3>Bugün Aramıza Katıl</h3>
            <span>Kendinizi nasıl tanımlarsınız?</span>
          </div>
          <!-- Category Side -->
          <div class="categoryWrapper mt-3">
            <div
              class="category d-flex align-items-center"
              [class.active]="category.checked"
              *ngFor="let category of categories"
              (click)="checkCategory(category)"
            >
              <div class="logos">
                <img *ngFor="let img of category.photos" [src]="img" alt="" />
              </div>
              <div class="info">
                <div class="name">
                  <span
                    class="d-flex justify-content-between align-items-center"
                  >
                    <b>{{ category.title }}</b>
                  </span>
                  <p>{{ category.subtitle }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- button wrapper -->
          <div class="d-flex flex-column mt-3">
            <button
              mat-flat-button
              color="primary"
              class="btn primaryButton d-flex align-items-center justify-content-center"
              (click)="handleStepperNextState(stepperPagesEnum.PAGE2)"
            >
              İlerle
              <mat-icon class="mx-3"> arrow_forward </mat-icon>
            </button>
            <div
              class="d-flex flex-row align-items-center justify-content-center rightSide"
            >
              <span>Zaten hesabınız var mı?</span>
              <button routerLink="/login" type="button" class="btn textButton">
                Giriş Yapın
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <div class="d-flex flex-column" style="gap: 80px">
          <!-- header -->
          <div class="d-flex flex-column gap-1 headerWrapper">
            <h3>Genel Bilgiler</h3>
            <span
              >Kaydınızı yapabilmemiz için bazı temel bilgilere ihtiyacımız
              olacak.</span
            >
          </div>
          <!-- Form Side -->
          <div class="row">
            <div class="col-12">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>İsim</mat-label>
                    <input
                      style="width: 100%"
                      class="w-100"
                      matInput
                      formControlName="first_name"
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Soyisim</mat-label>
                    <input class="w-100" matInput formControlName="last_name" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Eposta</mat-label>
                    <input
                      style="width: 100%"
                      class="w-100"
                      matInput
                      formControlName="email"
                    />
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Telefon</mat-label>
                    <input
                      style="width: 100%"
                      class="w-100"
                      matInput
                      formControlName="phone"
                      type="tel"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Şifre</mat-label>
                    <input
                      style="width: 100%"
                      class="w-100"
                      matInput
                      [type]="changePasswordState ? 'password' : 'text'"
                      formControlName="password"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      (click)="passwordState($event)"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="changePasswordState"
                    >
                      <mat-icon>{{
                        changePasswordState ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Şifreyi Doğrulayın</mat-label>
                    <input
                      style="width: 100%"
                      class="w-100"
                      matInput
                      [type]="changePassword2State ? 'password' : 'text'"
                      formControlName="password2"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      (click)="password2State($event)"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="changePassword2State"
                    >
                      <mat-icon>{{
                        changePassword2State ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                    <mat-error
                      *ngIf="
                        secondFormGroup.controls['password'].hasError(
                          'minlength'
                        )
                      "
                      >Şifreler Eşleşmiyor
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="col-12">
                <mat-checkbox
                  id="receiveNotifications"
                  formControlName="receiveNotifications"
                  >Unilocked haberleri hakkında eposta almak
                  istiyorum</mat-checkbox
                >
              </div>
              <div class="col-12">
                <mat-checkbox
                  id="acceptPolicies"
                  formControlName="acceptPolicies"
                >
                  Unilocked <a href="#">şartlarını </a> ve
                  <a href="#"> gizlilik politikasını </a> kabul
                  ediyorum</mat-checkbox
                >
              </div>
              <div class="col-12">
                <button
                  mat-flat-button
                  color="primary"
                  style="width: 100%"
                  class="btn primaryButton d-flex align-items-center justify-content-center"
                  (click)="handleStepperNextState(stepperPagesEnum.PAGE3)"
                  [disabled]="!secondFormGroup.valid"
                >
                  İlerle
                  <mat-icon class="mx-3"> arrow_forward </mat-icon>
                </button>
              </div>
              <div class="col-12 mt-3">
                <a
                  style="width: 100%"
                  class="btn lightButton d-flex align-items-center justify-content-center"
                  [href]="googleAuthUrl"
                >
                  <img src="assets/img/google.png" alt="" />
                  Google ile kaydolun
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup" *ngIf="!isProfessional">
      <form [formGroup]="thirdFormGroup">
        <div class="d-flex flex-column" style="gap: 80px">
          <!-- header -->
          <div class="d-flex flex-column gap-1 headerWrapper">
            <h3>Eğitim ve Konum Bilgileri</h3>
            <span style="text-align: left">
              Deneyimini özelleştirebilmemiz için nerede yaşadığın, hangi
              bölümde okuduğun gibi bilgilere ihtiyacımız olacak.
              <br />
              Bu bilgiler sonradan düzenlenebilir ve daha fazla bilgiyi
              sonrasında profilinizde ekleyebilirsiniz.
            </span>
          </div>
          <!-- Form Side -->
          <div class="row">
            <div class="col-12">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Okul</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="school"
                      [matAutocomplete]="auto"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let option of filteredSchools | async"
                        [value]="option.name"
                      >
                        {{ option.name }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Bölüm</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="department"
                      [matAutocomplete]="auto2"
                    />
                    <mat-autocomplete #auto2="matAutocomplete">
                      <mat-option
                        *ngFor="let option of filteredDepartments | async"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row mt-3">
                <div class="col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Başlangıç Tarihi</mat-label>
                    <input
                      style="width: 100%"
                      matInput
                      type="date"
                      formControlName="startDate"
                    />
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <mat-checkbox
                    id="didNotHaveEndDate"
                    formControlName="didNotHaveEndDate"
                    >Okulumun bitiş tarihi ile herhangi bir tahminim
                    bulunmuyor</mat-checkbox
                  >
                </div>
                <div class="col-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Bitiş Tarihi</mat-label>
                    <input matInput type="date" formControlName="endDate" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-md-6 col-12">
                  <button
                    type="button"
                    style="width: 100%"
                    class="btn lightButton d-flex align-items-center justify-content-center"
                    (click)="handleStepperNextState(stepperPagesEnum.PAGE2)"
                  >
                    Geri
                  </button>
                </div>
                <div class="col-md-6 col-12">
                  <button
                    mat-flat-button
                    color="primary"
                    type="submit"
                    style="width: 100%"
                    class="btn primaryButton d-flex align-items-center justify-content-center"
                    [loading]="creatingUser"
                    (click)="
                      handleStepperNextState(stepperPagesEnum.PAGE4, true)
                    "
                  >
                    İlerle
                    <mat-icon class="mx-3"> arrow_forward </mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <button
                mat-flat-button
                type="submit"
                style="width: 100%"
                class="btn lightButton d-flex align-items-center justify-content-center"
                [loading]="creatingUser"
                (click)="handleStepperNextState(stepperPagesEnum.PAGE4)"
              >
                Bu adımı atla
                <mat-icon class="mx-3"> arrow_forward </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="fourthFormGroup">
      <form [formGroup]="fourthFormGroup">
        <div class="d-flex flex-column" style="gap: 80px">
          <!-- header -->
          <div class="d-flex flex-column gap-1 headerWrapper">
            <h3>Profil Detayları</h3>
            <span style="text-align: left">
              Size hızlı bir başlangıç yapabilmemiz için bazı detaylara
              ihtiyacımız olacak, bu bilgiler ile profilinizi sizin için hazır
              hale getireceğiz.
              <br />
              <br />
              Profilinizi kayıt sonrasında daha detaylı olarak
              düzenleyebilirsiniz.
            </span>
            <span class="mt-4"><b>Profil Fotoğrafı</b></span>
            <span
              >Profilinizde görünecek profil fotoğrafınızı ayarlayın. Yüklenecek
              fotoğrafın en az 400x400px boyutunda ve jpg/png formatında olması
              gerekmektedir. 1:1 fotoğraf oranı tercih edilmelidir.
            </span>
          </div>
          <!-- Form Side -->
          <div class="row">
            <div
              class="col-12 d-flex align-items-center justify-content-center"
            >
              <img
                [src]="
                  uploadedFiles
                    ? uploadedFiles.imagesData.variants[0]
                    : 'assets/img/AnonymousyAvatar.png'
                "
                width="128"
                style="border-radius: 50%"
              />
            </div>
            <div class="col-12 mt-3">
              <div class="row">
                <div class="col-md-6 col-12">
                  <button
                    style="width: 100%"
                    class="btn primaryButton d-flex align-items-center justify-content-center"
                    (click)="uploadPhoto()"
                  >
                    Fotoğraf Yükle
                  </button>
                </div>
                <div class="col-md-6 col-12">
                  <button
                    style="width: 100%"
                    class="btn lightButton d-flex align-items-center justify-content-center"
                    (click)="removePhoto()"
                  >
                    Fotoğraf kaldır
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="row">
                <div class="col-12">
                  <span><strong>Kısa Özet</strong></span>
                </div>
                <div class="col-12">
                  <span style="text-align: left"
                    >Sizi tanımlayan yetenekler, ilgi alanları ve şuan neler ile
                    uğraştığınızı özetleyen kısa bir özet yazın - ne kadar kısa
                    ve öz, o kadar dikkat çekici!</span
                  >
                </div>
                <div class="col-12 mt-4">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Hakkında birşeyler yaz... </mat-label>
                    <textarea
                      style="width: 100%"
                      matInput
                      placeholder="Adım Mustafa. 20 yaşındayım. Yazılım konusuna meraklıyım ve kendimi bu alanda geliştirmek istiyorum..."
                      formControlName="about"
                    ></textarea>
                  </mat-form-field>
                </div>
                <div class="col-12 mt-2">
                  <button
                    mat-flat-button
                    color="primary"
                    style="width: 100%"
                    class="btn primaryButton d-flex align-items-center justify-content-center"
                    [loading]="updatingAvatar"
                    (click)="handleStepperNextState(stepperPagesEnum.NEXT)"
                  >
                    Kaydı Tamamla
                  </button>
                </div>
                <div class="col-12 mt-4">
                  <span style="cursor: pointer"
                    >Profilinizi sonra mı oluşturmak istiyorsunuz?
                    <a (click)="handleStepperNextState(stepperPagesEnum.NEXT)"
                      >Detayları Sonra Düzenleyin</a
                    ></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <div *ngIf="currentIndex == 4 || !registerAvaible" class="lastPage">
        <div class="headerContainer">
          <img src="assets/svg/starIcon.svg" width="112" />
          <h3 class="headerTitle">Unilocked’a Hoşgeldiniz 🎉</h3>
          <span
            >Aramıza katıldığın için çok mutluyuz
            {{
              secondFormGroup.controls["first_name"].value
                ? secondFormGroup.controls["first_name"].value
                : ""
            }}! Potansiyelinizi keşfetmeniz gereken başlangıç araçlarının
            hepsini keşfetmeye başlamanın tam sırası.</span
          >
          <br />
          <h2><a href="/">Maceraya başlamak için tıkla.</a></h2>
        </div>
        <div class="card" *ngIf="false">
          <div class="cardHeader">
            <img
              [src]="
                uploadedFiles
                  ? uploadedFiles.imagesData.variants[0]
                  : ' assets/img/AnonymousyAvatar.png'
              "
              width="64"
            />
            <div class="cardDesc">
              <span>{{ auth.user.display_name }}</span>
              <span
                >{{
                  thirdFormGroup.controls["school"].value
                    ? thirdFormGroup.controls["school"].value
                    : ""
                }}+{{
                  " " + thirdFormGroup.controls["department"].value
                    ? thirdFormGroup.controls["department"].value
                    : ""
                }}</span
              >
            </div>
          </div>
          <div class="cardBody">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <h3><b>Başlangıç 🌱</b></h3>
                  </div>
                  <div class="col-12 innerCardWrapper">
                    <div class="innerCard">
                      <img src="assets/svg/notificationIcon.svg" width="48" />
                      <span class="smallTitle">BAŞLANGIÇ NOKTANIZ</span>
                      <span class="bigTitle">Keşif 🧭</span>
                    </div>
                    <div class="innerCard">
                      <img src="assets/svg/avatarStepperIcon.svg" width="48" />
                      <span class="smallTitle">DÜZENLEMEYE BAŞLAYIN</span>
                      <span class="bigTitle">Profil 🙋🏻</span>
                    </div>
                    <div class="innerCard">
                      <img src="assets/svg/peopleIcon.svg" width="48" />
                      <span class="smallTitle">BAĞLANTILAR KURUN</span>
                      <span class="bigTitle">Kişiler 🌐</span>
                    </div>
                    <div class="innerCard">
                      <img src="assets/svg/newsPaperIcon.svg" width="48" />
                      <span class="smallTitle">HAYALİNİZDEKİ STAJI BULUN</span>
                      <span class="bigTitle">İlanlar 🖊️</span>
                    </div>
                    <div class="innerCard">
                      <img src="assets/svg/layersIcon.svg" width="48" />
                      <span class="smallTitle"
                        >KEŞFETMENİN SINIRLARINI KALDIRIN</span
                      >
                      <span class="bigTitle">Katalog 📖</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <h3><b>Unilocked BLOG İLE İPUÇLARI 💡</b></h3>
                  </div>
                  <div class="col-12 innerCardWrapper">
                    <div class="innerCard2">
                      <img
                        src="assets/svg/notificationIconSquare.svg"
                        width="48"
                      />
                      <div class="d-flex flex-column">
                        <span class="bigTitle">Keşif Sayfasını Keşfetmek</span>
                        <span class="desc"
                          >Keşif sayfasını en iyi şekilde kullanmaya başlamak
                          için ipuçları burada.</span
                        >
                        <span class="smallTitle"
                          >Unilocked tarafından • 2 dakika okuma</span
                        >
                      </div>
                    </div>
                    <div class="innerCard2">
                      <img
                        src="assets/svg/avatarStepperIconSquare.svg"
                        width="48"
                      />
                      <div class="d-flex flex-column">
                        <span class="bigTitle">Unilocked Profili 101</span>
                        <span class="desc"
                          >Profil sayfanızı en optimal şekilde düzenleyin, her
                          aşamada öne çıkan aday olun.</span
                        >
                        <span class="smallTitle"
                          >Felya Varol tarafından • 5 dakika okuma</span
                        >
                      </div>
                    </div>
                    <div class="innerCard2">
                      <img src="assets/svg/peopleIconSquare.svg" width="48" />
                      <div class="d-flex flex-column">
                        <span class="bigTitle">Unilocked Topluluğu</span>
                        <span class="desc"
                          >Unilocked Topluluğunu herkes için harika bir yer
                          yapmaya çalışıyoruz, ancak bunu sizsiz
                          yapamayız!</span
                        >
                        <span class="smallTitle"
                          >Unilocked tarafından • 11 dakika okuma</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
  <ng-template #noRegister>
    <div class="form d-flex flex-column align-items-center">
      <div class="title">
        <h1>Çok Yakında Seninle</h1>
        <p>Unilocked yakın zamanda beta testlerine başlayacak. Hazır ol!</p>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="currentIndex < 4" class="right-side">
  <div *ngIf="currentIndex == 0 || !registerAvaible" class="mainContentWrapper">
    <div class="contentWrapper">
      <img
        src="assets/svg/RocketIcon.svg"
        width="112"
        height="112"
        alt="rocketIcon"
      />
      <h1 class="headerTitle">Gelecek Burada Başlıyor</h1>
      <span
        >Unilocked üyeliği ile öğrenciler ve profesyoneller iletişime geçebilir,
        projeler ve staj imkanları oluşturulabilir.</span
      >
    </div>
    <div (click)="expanded = !expanded" class="card">
      <div class="d-flex align-items-center justify-content-between">
        <h1>Hesap çeşitleri nedir?</h1>
        <mat-icon>{{
          expanded ? "keyboard_arrow_up" : "keyboard_arrow_down"
        }}</mat-icon>
      </div>
      <p [class.expanded]="expanded" class="longText">
        Öğrenci hesaplar, kişisel profillerdir ve en temel hesap çeşididir.
        <br />
        <br />
        Profesyonel hesaplar, kişisel profesyonel profiller ve
        kurum/şirket/teknopark profilleri olarak ayrılırlar. Profesyoneller
        kendilerine özel profiller oluşturabilirler.
        Kurumlar/şirketler/teknopark yönetimleri profil oluşturmak için yönetici
        rolünde bir profesyonel hesabı kullanmak zorundadır..
        <br />
        <br />
        Topluluk hesapları, hem öğrenci hem de profesyonel profiller tarafından
        oluşturulabilir.
      </p>
    </div>
  </div>
  <div *ngIf="currentIndex == 1 || !registerAvaible" class="mainContentWrapper">
    <div class="contentWrapper">
      <img
        src="assets/svg/worldIcon.svg"
        width="112"
        height="112"
        alt="worldIcon"
      />
      <h1 class="headerTitle">Herkes için Unilocked</h1>
      <span
        >Unilocked, her türlü sektörden işinde uzman ve geleceğin uzmanlarını
        bir araya getirecek sayısız sayfa bulundurur.</span
      >
    </div>
    <div class="row mt-4">
      <div class="col-12 mb-3"><b>Şifreniz</b></div>
      <div class="col-12">
        <div class="d-flex">
          <mat-icon
            [style.color]="
              hasPasswordError('minlength') ? '#495057' : '#37B24D'
            "
            >{{
              hasPasswordError("minlength")
                ? "check_box_outline_blank"
                : "check_box"
            }}</mat-icon
          >
          8 ile 72 karakter arasında olmalıdır
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex">
          <mat-icon
            [style.color]="
              hasPasswordError('requiresUppercase') ? '#495057' : '#37B24D'
            "
            >{{
              hasPasswordError("requiresUppercase")
                ? "check_box_outline_blank"
                : "check_box"
            }}</mat-icon
          >
          En az bir büyük harf içermelidir
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex">
          <mat-icon
            [style.color]="
              hasPasswordError('requiresDigit') ? '#495057' : '#37B24D'
            "
            >{{
              hasPasswordError("requiresDigit")
                ? "check_box_outline_blank"
                : "check_box"
            }}</mat-icon
          >
          En az bir sayı içermelidir
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex">
          <mat-icon
            [style.color]="
              hasPasswordError('requiresSpecialChars') ? '#495057' : '#37B24D'
            "
            >{{
              hasPasswordError("requiresSpecialChars")
                ? "check_box_outline_blank"
                : "check_box"
            }}</mat-icon
          >
          En az bir özel karakter içermelidir
        </div>
      </div>
    </div>
    <div
      style="
        background-color: #edeefe;
        border-radius: 7px;
        width: 100%;
        padding: 32px;
      "
    >
      <img src="assets/svg/compassIcon.svg" width="24" />
      <div class="row">
        <div class="col-12">
          <h3><b>Keşif</b></h3>
        </div>
        <div class="col-12">
          <span
            >Sizin gibi her zaman bir adım ileri gitmek için can atanlar ile
            tanışın, ilham verene gönderilere göz atın.</span
          >
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentIndex == 2 || !registerAvaible" class="mainContentWrapper">
    <div class="contentWrapper">
      <img
        src="assets/svg/educationIcon.svg"
        width="112"
        height="112"
        alt="educationIcon"
      />
      <h1 class="headerTitle">Okulunuz ve Topluluklarınız Burada</h1>
      <span
        >Okulunuz, okul topluluklarınız ve okul arkadaşlarınızın hepsi tek bir
        platformda, Unilocked’da.</span
      >
    </div>

    <div
      style="
        background-color: #edeefe;
        border-radius: 7px;
        width: 100%;
        padding: 32px;
      "
    >
      <img src="assets/svg/compassIcon.svg" width="24" />
      <div class="row">
        <div class="col-12">
          <h3><b>Keşif</b></h3>
        </div>
        <div class="col-12">
          <span
            >Sizin gibi her zaman bir adım ileri gitmek için can atanlar ile
            tanışın, ilham verene gönderilere göz atın.</span
          >
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentIndex == 3 || !registerAvaible" class="mainContentWrapper">
    <div class="contentWrapper">
      <img
        src="assets/svg/avatarStepperIcon.svg"
        width="112"
        height="112"
        alt="avatarStepperIcon"
      />
      <h1 class="headerTitle">Kendinizi Tanıtmanız İçin Gereken Her Şey</h1>
      <span
        >Dünyaya açılmanız için gereken her şey tek bir platformda! Profilinizi
        istediğiniz gibi düzenleyin, deneyimlerinizi ve yeteneklerinizi herkese
        sergileyin.</span
      >
    </div>

    <div
      style="
        background-color: #edeefe;
        border-radius: 7px;
        width: 100%;
        padding: 32px;
      "
    >
      <img src="assets/svg/compassIcon.svg" width="24" />
      <div class="row">
        <div class="col-12">
          <h3><b>Keşif</b></h3>
        </div>
        <div class="col-12">
          <span
            >Sizin gibi her zaman bir adım ileri gitmek için can atanlar ile
            tanışın, ilham verene gönderilere göz atın.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

</div>