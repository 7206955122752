import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { AppHttpClient } from '@common/core/http/http.service';
import { SettingsService } from '@common/core/settings/settings.service';
import { UploadService } from '@common/core/upload/upload.service';
import { GlobalSearchService } from '@common/services/global-search.service';
import { PasswordValidators } from '@common/services/password-validator.service';
import { ProfileService } from '@common/services/profile.service';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { BehaviorSubject } from 'rxjs';
import { ProfileEditingService } from 'src/app/admin/profile-editing/profile-editing.service';
import { File } from 'src/app/models/file';
import { environment } from 'src/environments/environment';

export enum STEPPER_PAGES {
  PAGE1 = 0,
  PAGE2 = 1,
  PAGE3 = 2,
  PAGE4 = 3,
  PAGE5 = 4,
  NEXT = 5,
}

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  expanded: boolean = false;
  changePasswordState: boolean = true;
  changePassword2State: boolean = true;
  stepperPagesEnum = STEPPER_PAGES;
  uploadedFiles: File;
  userId: string;
  public currentIndex = 0;

  public googleAuthUrl = environment.api + '/api/auth/social/google/login';


  public categories = [
    {
      photos: [
        '../../../assets/img/avatar1.png',
        '../../../assets/img/avatar2.png',
        '../../../assets/img/avatar3.png',
      ],
      title: 'Öğrenciyim',
      checked: true,
      subtitle:
        'Kendimi geliştirmek ve benim gibi düşünenler ile birlikte olmak istiyorum.',
    },
    {
      photos: [
        '../../../assets/img/teknokent.png',
        '../../../assets/img/turkcell.png',
        '../../../assets/img/avatar4.png',
      ],
      title: 'Profesyonelim',
      checked: false,
      subtitle:
        'Genç yetenekleri keşfetmek, iş ve staj imkanlarını paylaşmak ve alanım hakkında öğrencileri bilgilendirmek istiyorum.',
    },
  ];

  public get selectedIndex() {
    if (this.stepper) {
      return this.stepper.selectedIndex;
    } else {
      return 0;
    }
  }

  public get isProfessional() {
    return this.categories.find((x) => x.checked).title == 'Profesyonelim';
  }

  public changeGoogleAuthUrl() {
    this.googleAuthUrl = environment.api + '/api/auth/social/google/login?userType='+(this.isProfessional ? 'Professional' : 'Student')+'&redirect=' + encodeURIComponent((this.auth.redirect || '/home'));

  }

  public checkCategory(category: any) {
    this.categories.forEach((category) => {
      category.checked = false;
    });
    category.checked = true;

    this.changeGoogleAuthUrl();


  }

  public firstFormGroup = this._formBuilder.group({});
  public secondFormGroup = this._formBuilder.group(
    {
      email: ['', [Validators.email, Validators.required]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: [
        '',
        [Validators.required, Validators.pattern(/^(\+90|0)(5\d{9})$/)],
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          PasswordValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          PasswordValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          PasswordValidators.patternValidator(
            new RegExp('(?=.*[$@^!%*?&+.])'),
            {
              requiresSpecialChars: true,
            }
          ),
        ]),
      ],
      password2: ['', Validators.required],
      receiveNotifications: [false],
      acceptPolicies: [false, Validators.requiredTrue],
    },
    {
      validators: PasswordValidators.MatchValidator,
    }
  );
  public thirdFormGroup = this._formBuilder.group({
    school: [''],
    department: [''],
    startDate: [''],
    endDate: [''],
    didNotHaveEndDate: [''],
  });
  public fourthFormGroup = this._formBuilder.group({
    about: [''],
  });
  public error: string;

  public needEmailConfirmation: boolean = false;

  public filteredSchools = new BehaviorSubject<any[]>([]);
  public filteredDepartments = new BehaviorSubject<string[]>([]);

  constructor(
    public auth: AuthService,
    private _formBuilder: FormBuilder,
    public theme: AppThemeService,
    public searchService: GlobalSearchService,
    public settings: SettingsService,
    private uploadService: UploadService,
    private profiles: ProfileService,
    private http:AppHttpClient
  ) {}

  public getLocalizedName(doc) {
    // get browser language
    const lang = navigator.language.split('-')[0];
    // find localized name
    const name = (doc.localizedNames || []).find((x) => x.locale == lang);
    // if not found return default name
    console.log(doc);
    return name ? name.name : doc.name;
  }

  public get registerAvaible() {
    return this.settings.get('register.enabled');
  }

  ngOnInit(): void {
    this.thirdFormGroup.get('school').valueChanges.subscribe((value) => {
      this.searchService.searchSchools(value).subscribe((response) => {
        this.filteredSchools.next(response.data.organizations);
      });
    });
    this.thirdFormGroup.get('department').valueChanges.subscribe((value) => {
      this.searchService.searchDepartments(value).subscribe((response) => {
        this.filteredDepartments.next(
          response.data.departments.map((x) => x.name)
        );
      });
    });
   
    this.thirdFormGroup
      .get('didNotHaveEndDate')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.thirdFormGroup.get('endDate').disable();
        } else {
          this.thirdFormGroup.get('endDate').enable();
        }
      });

      this.changeGoogleAuthUrl();
  }

  public hasPasswordError(error: string) {
    return (
      !this.secondFormGroup.get('password').value ||
      this.secondFormGroup.controls['password'].hasError(error)
    );
  }
  passwordState(event: MouseEvent) {
    this.changePasswordState = !this.changePasswordState;
    event.stopPropagation();
  }
  password2State(event: MouseEvent) {
    this.changePassword2State = !this.changePassword2State;
    event.stopPropagation();
  }

  public creatingUser = false;
  public updatingAvatar = false;

  handleStepperNextState(nextPage: STEPPER_PAGES, isRequired: boolean = false) {
    if (nextPage === STEPPER_PAGES.PAGE4 && isRequired) {
      this.thirdFormGroup.controls['school'].addValidators(Validators.required);
      this.thirdFormGroup.controls['school'].updateValueAndValidity();
      this.thirdFormGroup.controls['department'].addValidators(
        Validators.required
      );
      this.thirdFormGroup.controls['department'].updateValueAndValidity();
      this.thirdFormGroup.controls['startDate'].addValidators(
        Validators.required
      );
      this.thirdFormGroup.controls['startDate'].updateValueAndValidity();
      this.thirdFormGroup.controls['endDate'].addValidators(
        Validators.required
      );
      this.thirdFormGroup.controls['endDate'].updateValueAndValidity();

      if (this.thirdFormGroup.valid) {
        this.submitForms(nextPage);
      }
    } else if (nextPage === STEPPER_PAGES.PAGE4) {
      this.thirdFormGroup.controls['school'].clearValidators();
      this.thirdFormGroup.controls['school'].updateValueAndValidity();
      this.thirdFormGroup.controls['department'].clearValidators();
      this.thirdFormGroup.controls['department'].updateValueAndValidity();
      this.thirdFormGroup.controls['startDate'].clearValidators();
      this.thirdFormGroup.controls['startDate'].updateValueAndValidity();
      this.thirdFormGroup.controls['endDate'].clearValidators();
      this.thirdFormGroup.controls['endDate'].updateValueAndValidity();
      this.submitForms(nextPage);
    } else if (nextPage !== STEPPER_PAGES.NEXT) {
      this.stepper.selectedIndex = nextPage;
    }

    if (nextPage === STEPPER_PAGES.NEXT) {
      // if image uploaded
      if (this.uploadedFiles) {
        const data = {
          id: this.userId,
          avatar: this.uploadedFiles.imagesData.variants[0],
          avatarFile: this.uploadedFiles,
          about: this.fourthFormGroup.controls['about'].value,
          type: 'User',
        };
        this.updatingAvatar = true;
        this.profiles.updateAvatarAndAbout(data).subscribe((response) => {
          this.updatingAvatar = false;
          this.stepper.next();
        });
      } else {
        this.submitForms(nextPage);
      }
    }
  }

  submitForms(nextPage: STEPPER_PAGES) {
    if (this.firstFormGroup.valid && this.secondFormGroup.valid) {
      const data = {
        ...this.firstFormGroup.value,
        ...this.secondFormGroup.value,
        ...this.thirdFormGroup.value,
      };
      this.creatingUser = true;
      this.auth.register(data).subscribe({
        next: (response) => {
          console.log('response', response);
          this.http.setToken(response.token);
          this.needEmailConfirmation = response.needEmailConfirmation;
          this.userId = response.userId;
          this.stepper.selectedIndex = nextPage;
          this.auth.user = response.user;
          this.auth.user$.next(response.user);
          this.creatingUser = false;
        },
        error: (error) => {
          this.error = error;
          console.log('error', this.error);
          this.creatingUser = false;
        },
      });
    }
  }
  uploadPhoto() {
    this.uploadService
      .openUploadDialog$('image', false, {
        aspectRatio: 1,
      })
      .subscribe((files) => {
        console.log('files', files);
        this.uploadedFiles = files[0];
      });
  }
  removePhoto() {
    this.uploadedFiles = null;
  }
}
